<template>
  <div class="recomendFoodPage">
    <topbar title="推廣餐品"></topbar>
    <div class="tabbar">
      <div @click="changeTab(1)" :class="{ active: tabType == 1 }">
        <p>外送速遞</p>
        <i></i>
      </div>
      <div @click="changeTab(2)" :class="{ active: tabType == 2 }">
        <p>堂食點餐</p>
        <i></i>
      </div>
    </div>
    <div class="foodList">
      <div class="itenmBox" v-for="item in foodList" :key="item">
        <recomend-food-item></recomend-food-item>
      </div>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import RecomendFoodItem from "../../components/food/RecomendFoodItem.vue";
export default {
  name: "RecomendFood",
  data() {
    return {
      tabType: 1,
      foodList: [1, 2, 3, 4],
    };
  },
  methods: {
    changeTab(type) {
      this.tabType = type;
    },
  },
  components: {
    Topbar,
    RecomendFoodItem,
  },
};
</script>
<style scoped>
.recomendFoodPage {
  background-color: #f2f3f5;
  height: 100vh;
  overflow: scroll;
}
.tabbar {
    width: 100%;
  height: 0.44rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  background-color: #fff;
  position: fixed;
  left: 0;
}
.tabbar div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tabbar i {
  width: 20px;
  height: 2px;
  background: #fff;
  opacity: 1;
  border-radius: 3px;
  margin-top: 0.1rem;
}
.tabbar div.active {
  color: #e4022b;
}
.tabbar div.active i {
  background: #e4022b;
}
.foodList {
  padding: 0.16rem;
  box-sizing: border-box;
  margin-top: .44rem;
}
.itenmBox {
  margin-bottom: 0.12rem;
}
</style>