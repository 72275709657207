<template>
  <div class="foodItem">
    <img class="cover" src="@/assets/icon/banner.png" alt="" />
    <div class="foodInfo">
      <p class="name">鸡腿套餐</p>
      <p class="foodDesc">
        鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐鸡腿套餐
      </p>
      <div class="price">
        <p>{{$store.state.money[$store.state.area]}} <span>278起</span><span class="linePrice">{{$store.state.money[$store.state.area]}}333</span></p>
        <p class="numBtn">+</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.foodItem {
  border-radius: 0.12rem 0.12rem 0 0;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.cover {
  width: 100%;
  object-fit: cover;
}
.foodInfo {
  width: 100%;
  padding: 0.16rem 0.1rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.12rem 0.12rem 0 0;
  margin-top: -0.12rem;
}
.name {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  text-align: left;
}
.foodDesc {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #8b8c8c;
  text-align: justify;
  margin: 0.1rem 0 0.1rem;
}
.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .1rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #03060d;
}
.price span{
    font-size: .16rem;
}
.numBtn{
    width: .2rem;
    height: .2rem;
    line-height: .2rem;
    text-align: center;
    font-size: .16rem;
    background-color: #e4022b;
    border-radius: .02rem;
    color: #fff;
}
.linePrice{
    font-size: .1rem !important;
    color: #ddd;
    position: relative;
    margin-left: .08rem;
}
.linePrice::after{
    content: "";
    display: inline-block;
    width: .5rem;
    height: .02rem;
    background-color: #ddd;
    position: absolute;
    left: 0;
    top: .07rem;

}
</style>
